import React from "react";
import { NavLink } from "react-router-dom";

const MobilMenu = ({ openMenu }) => {
  return (
    <div className="flex justify-between items-center   ">
      <NavLink
        to={"/" || "/home"}
        className="flex flex-col cursor-pointer justify-center items-center w-1/5 py-3 px-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.0006 10C20.0006 9.73478 19.8952 9.48043 19.7077 9.29289C19.5201 9.10536 19.2658 9 19.0006 9C18.7354 9 18.481 9.10536 18.2935 9.29289C18.1059 9.48043 18.0006 9.73478 18.0006 10H20.0006ZM6.00057 10C6.00057 9.73478 5.89521 9.48043 5.70768 9.29289C5.52014 9.10536 5.26579 9 5.00057 9C4.73535 9 4.481 9.10536 4.29346 9.29289C4.10593 9.48043 4.00057 9.73478 4.00057 10H6.00057ZM20.2936 12.707C20.4822 12.8892 20.7348 12.99 20.997 12.9877C21.2592 12.9854 21.51 12.8802 21.6954 12.6948C21.8808 12.5094 21.986 12.2586 21.9882 11.9964C21.9905 11.7342 21.8897 11.4816 21.7076 11.293L20.2936 12.707ZM12.0006 3L12.7076 2.293C12.52 2.10553 12.2657 2.00021 12.0006 2.00021C11.7354 2.00021 11.4811 2.10553 11.2936 2.293L12.0006 3ZM2.29357 11.293C2.19806 11.3852 2.12188 11.4956 2.06947 11.6176C2.01706 11.7396 1.98947 11.8708 1.98832 12.0036C1.98717 12.1364 2.01247 12.2681 2.06275 12.391C2.11303 12.5139 2.18728 12.6255 2.28117 12.7194C2.37507 12.8133 2.48672 12.8875 2.60962 12.9378C2.73251 12.9881 2.86419 13.0134 2.99697 13.0123C3.12975 13.0111 3.26097 12.9835 3.38297 12.9311C3.50498 12.8787 3.61532 12.8025 3.70757 12.707L2.29357 11.293ZM7.00057 22H17.0006V20H7.00057V22ZM20.0006 19V10H18.0006V19H20.0006ZM6.00057 19V10H4.00057V19H6.00057ZM21.7076 11.293L12.7076 2.293L11.2936 3.707L20.2936 12.707L21.7076 11.293ZM11.2936 2.293L2.29357 11.293L3.70757 12.707L12.7076 3.707L11.2936 2.293ZM17.0006 22C17.7962 22 18.5593 21.6839 19.1219 21.1213C19.6845 20.5587 20.0006 19.7956 20.0006 19H18.0006C18.0006 19.2652 17.8952 19.5196 17.7077 19.7071C17.5201 19.8946 17.2658 20 17.0006 20V22ZM7.00057 20C6.73535 20 6.481 19.8946 6.29346 19.7071C6.10593 19.5196 6.00057 19.2652 6.00057 19H4.00057C4.00057 19.7956 4.31664 20.5587 4.87925 21.1213C5.44186 21.6839 6.20492 22 7.00057 22V20Z"
            fill="#262F44"
          />
        </svg>
        <p className="font-medium text-[10px] text-center">الرئيسية</p>
      </NavLink>
      <NavLink
        to={"/courses"}
        className="flex flex-col cursor-pointer justify-center items-center w-1/5 py-3 px-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M16.711 11.106L10.237 7.86801C10.054 7.77643 9.85058 7.73317 9.64614 7.74236C9.44169 7.75155 9.24299 7.81287 9.06892 7.92049C8.89485 8.02812 8.7512 8.17848 8.65163 8.35727C8.55205 8.53607 8.49985 8.73736 8.5 8.94201V15.058C8.49985 15.2627 8.55205 15.464 8.65163 15.6427C8.7512 15.8215 8.89485 15.9719 9.06892 16.0795C9.24299 16.1872 9.44169 16.2485 9.64614 16.2577C9.85058 16.2668 10.054 16.2236 10.237 16.132L16.711 12.894C16.8769 12.8109 17.0164 12.6832 17.1138 12.5253C17.2113 12.3674 17.2629 12.1856 17.2629 12C17.2629 11.8145 17.2113 11.6326 17.1138 11.4747C17.0164 11.3168 16.8769 11.1891 16.711 11.106Z"
            stroke="#262F44"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z"
            stroke="#262F44"
            strokeWidth="2"
          />
        </svg>
        <p className="font-medium text-[10px] text-center">الكورسات</p>
      </NavLink>
      <NavLink
        to={"/analysis"}
        className="flex flex-col cursor-pointer justify-center items-center w-1/5 py-3 px-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M16.091 18L18.689 22.5H16.958L14.36 18H10.649L8.051 22.5H6.32L8.918 18H4.25C4.05109 18 3.86032 17.921 3.71967 17.7803C3.57902 17.6397 3.5 17.4489 3.5 17.25V4.5H2C1.80109 4.5 1.61032 4.42098 1.46967 4.28033C1.32902 4.13968 1.25 3.94891 1.25 3.75C1.25 3.55109 1.32902 3.36032 1.46967 3.21967C1.61032 3.07902 1.80109 3 2 3H23C23.1989 3 23.3897 3.07902 23.5303 3.21967C23.671 3.36032 23.75 3.55109 23.75 3.75C23.75 3.94891 23.671 4.13968 23.5303 4.28033C23.3897 4.42098 23.1989 4.5 23 4.5H21.5V17.25C21.5 17.4489 21.421 17.6397 21.2803 17.7803C21.1397 17.921 20.9489 18 20.75 18H16.091ZM20 4.5H5V16.5H20V4.5ZM8.75 10.5C8.94891 10.5 9.13968 10.579 9.28033 10.7197C9.42098 10.8603 9.5 11.0511 9.5 11.25V12.75C9.5 12.9489 9.42098 13.1397 9.28033 13.2803C9.13968 13.421 8.94891 13.5 8.75 13.5C8.55109 13.5 8.36032 13.421 8.21967 13.2803C8.07902 13.1397 8 12.9489 8 12.75V11.25C8 11.0511 8.07902 10.8603 8.21967 10.7197C8.36032 10.579 8.55109 10.5 8.75 10.5ZM12.5 9C12.6989 9 12.8897 9.07902 13.0303 9.21967C13.171 9.36032 13.25 9.55109 13.25 9.75V12.75C13.25 12.9489 13.171 13.1397 13.0303 13.2803C12.8897 13.421 12.6989 13.5 12.5 13.5C12.3011 13.5 12.1103 13.421 11.9697 13.2803C11.829 13.1397 11.75 12.9489 11.75 12.75V9.75C11.75 9.55109 11.829 9.36032 11.9697 9.21967C12.1103 9.07902 12.3011 9 12.5 9ZM16.25 7.5C16.4489 7.5 16.6397 7.57902 16.7803 7.71967C16.921 7.86032 17 8.05109 17 8.25V12.75C17 12.9489 16.921 13.1397 16.7803 13.2803C16.6397 13.421 16.4489 13.5 16.25 13.5C16.0511 13.5 15.8603 13.421 15.7197 13.2803C15.579 13.1397 15.5 12.9489 15.5 12.75V8.25C15.5 8.05109 15.579 7.86032 15.7197 7.71967C15.8603 7.57902 16.0511 7.5 16.25 7.5Z"
            fill="#262F44"
          />
        </svg>
        <p className="font-medium text-[10px] text-center">الإحصائيات</p>
      </NavLink>
      <NavLink
        to={"/exams"}
        className="flex flex-col cursor-pointer justify-center items-center  w-1/5 py-3 px-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M14 14.538C14.2067 14.538 14.3883 14.4597 14.545 14.303C14.7023 14.1457 14.781 13.964 14.781 13.758C14.781 13.5513 14.7023 13.3697 14.545 13.213C14.3877 13.0563 14.206 12.9777 14 12.977C13.7933 12.977 13.6117 13.0557 13.455 13.213C13.2983 13.3703 13.2197 13.552 13.219 13.758C13.219 13.9647 13.2977 14.1463 13.455 14.303C13.6117 14.4597 13.7933 14.538 14 14.538ZM13.558 11.723H14.442C14.468 11.3037 14.534 10.988 14.64 10.776C14.7467 10.564 15.0027 10.268 15.408 9.888C15.8307 9.504 16.1253 9.16067 16.292 8.858C16.4587 8.55667 16.542 8.21033 16.542 7.819C16.542 7.14567 16.302 6.58467 15.822 6.136C15.342 5.68667 14.7347 5.462 14 5.462C13.4447 5.462 12.9513 5.612 12.52 5.912C12.0887 6.212 11.7603 6.62067 11.535 7.138L12.346 7.496C12.5347 7.10533 12.7647 6.812 13.036 6.616C13.3067 6.42067 13.628 6.323 14 6.323C14.4767 6.323 14.8723 6.46433 15.187 6.747C15.5003 7.02967 15.657 7.39467 15.657 7.842C15.657 8.114 15.581 8.367 15.429 8.601C15.2763 8.83433 15.014 9.116 14.642 9.446C14.2207 9.814 13.9347 10.1517 13.784 10.459C13.6333 10.7657 13.558 11.187 13.558 11.723ZM8.615 17C8.155 17 7.771 16.846 7.463 16.538C7.15433 16.2293 7 15.845 7 15.385V4.615C7 4.155 7.15433 3.771 7.463 3.463C7.771 3.15433 8.155 3 8.615 3H19.385C19.845 3 20.229 3.15433 20.537 3.463C20.8457 3.771 21 4.155 21 4.615V15.385C21 15.845 20.846 16.2293 20.538 16.538C20.2293 16.846 19.845 17 19.385 17H8.615ZM8.615 16H19.385C19.5383 16 19.6793 15.936 19.808 15.808C19.936 15.6793 20 15.5383 20 15.385V4.615C20 4.46167 19.936 4.32067 19.808 4.192C19.6793 4.064 19.5383 4 19.385 4H8.615C8.46167 4 8.32067 4.064 8.192 4.192C8.064 4.32067 8 4.46167 8 4.615V15.385C8 15.5383 8.064 15.6793 8.192 15.808C8.32067 15.936 8.46167 16 8.615 16ZM5.615 20C5.155 20 4.771 19.846 4.463 19.538C4.15433 19.2293 4 18.845 4 18.385V6.615H5V18.385C5 18.5383 5.064 18.6793 5.192 18.808C5.32067 18.936 5.46167 19 5.615 19H17.385V20H5.615Z"
            fill="#262F44"
          />
        </svg>
        <p className="font-medium text-[10px] text-center ">الإختبارات</p>
      </NavLink>
      <div
        onClick={() => openMenu(true)}
        className="flex flex-col cursor-pointer justify-center items-center w-1/5 py-3 px-3 "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.8125 12C20.8125 12.1492 20.7532 12.2923 20.6477 12.3977C20.5423 12.5032 20.3992 12.5625 20.25 12.5625H3.75C3.60082 12.5625 3.45774 12.5032 3.35225 12.3977C3.24676 12.2923 3.1875 12.1492 3.1875 12C3.1875 11.8508 3.24676 11.7077 3.35225 11.6023C3.45774 11.4968 3.60082 11.4375 3.75 11.4375H20.25C20.3992 11.4375 20.5423 11.4968 20.6477 11.6023C20.7532 11.7077 20.8125 11.8508 20.8125 12ZM3.75 6.5625H20.25C20.3992 6.5625 20.5423 6.50324 20.6477 6.39775C20.7532 6.29226 20.8125 6.14918 20.8125 6C20.8125 5.85082 20.7532 5.70774 20.6477 5.60225C20.5423 5.49676 20.3992 5.4375 20.25 5.4375H3.75C3.60082 5.4375 3.45774 5.49676 3.35225 5.60225C3.24676 5.70774 3.1875 5.85082 3.1875 6C3.1875 6.14918 3.24676 6.29226 3.35225 6.39775C3.45774 6.50324 3.60082 6.5625 3.75 6.5625ZM20.25 17.4375H3.75C3.60082 17.4375 3.45774 17.4968 3.35225 17.6023C3.24676 17.7077 3.1875 17.8508 3.1875 18C3.1875 18.1492 3.24676 18.2923 3.35225 18.3977C3.45774 18.5032 3.60082 18.5625 3.75 18.5625H20.25C20.3992 18.5625 20.5423 18.5032 20.6477 18.3977C20.7532 18.2923 20.8125 18.1492 20.8125 18C20.8125 17.8508 20.7532 17.7077 20.6477 17.6023C20.5423 17.4968 20.3992 17.4375 20.25 17.4375Z"
            fill="#262F44"
          />
        </svg>
        <p className="font-medium text-[10px] text-center">القائمة</p>
      </div>
    </div>
  );
};

export default MobilMenu;
